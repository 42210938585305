import { useCallback, useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#__next');

interface ModalProps {
  className?: string; // Applied to content.
  onDismiss: () => void;
  showing: boolean;
}

interface PopupProps extends ModalProps {
  desktopHeight?: string;
  desktopWidth?: string;
  size: 'partial' | 'full';
}

export const Popup: React.FC<PopupProps> = ({
  children,
  className,
  desktopHeight,
  desktopWidth,
  onDismiss,
  size,
  showing,
}) => {
  const [wasOpen, setWasOpen] = useState(false);

  const afterOpen = useCallback(() => {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  }, []);

  const afterClose = useCallback(() => {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  }, []);

  if (size === 'partial') {
    return (
      <Modal
        isOpen={showing}
        onRequestClose={onDismiss}
        className={{
          base: `popup-content--base absolute left-0 lg:left-1/2 bottom-0 lg:bottom-auto lg:top-1/2 p-0 border-0 w-full h-1/2 lg:h-auto lg:max-w-lg lg:max-h-modal bg-white lg:shadow-2xl focus:outline-none duration-200 ${className}`,
          afterOpen: wasOpen ? 'popup-content--after-open' : '',
          beforeClose: 'popup-content--before-close',
        }}
        overlayClassName={{
          base: 'popup-overlay--base dark',
          afterOpen: wasOpen ? 'popup-overlay--after-open' : '',
          beforeClose: 'popup-overlay--before-close',
        }}
        closeTimeoutMS={200}
        onAfterOpen={afterOpen}
        onAfterClose={afterClose}
      >
        {children}
      </Modal>
    );
  }

  const width = `${desktopWidth ? desktopWidth : 'lg:max-w-5xl'}`;
  const height = `${desktopHeight ? desktopHeight : 'lg:max-h-modal'}`;

  return (
    <Modal
      isOpen={showing}
      onRequestClose={onDismiss}
      className={{
        base: `popup-content--base absolute left-0 lg:left-1/2 top-0 lg:top-1/2 p-0 border-0 w-full h-full bg-white lg:shadow-2xl focus:outline-none duration-300 ${width} ${height} ${className}`,
        afterOpen: wasOpen ? 'popup-content--after-open' : '',
        beforeClose: 'popup-content--before-close',
      }}
      overlayClassName={{
        base: 'popup-overlay--base',
        afterOpen: wasOpen ? 'popup-overlay--after-open' : '',
        beforeClose: 'popup-overlay--before-close',
      }}
      closeTimeoutMS={300}
      onAfterOpen={afterOpen}
      onAfterClose={afterClose}
    >
      {children}
    </Modal>
  );
};

export const Sidebar: React.FC<ModalProps> = ({ children, className, showing, onDismiss }) => {
  const [wasOpen, setWasOpen] = useState(false);

  const afterOpen = useCallback(() => {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  }, []);

  const afterClose = useCallback(() => {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  }, []);

  return (
    <Modal
      isOpen={showing}
      onRequestClose={onDismiss}
      className={{
        base: `sidebar-content--base absolute top-0 right-0 bottom-0 p-0 border-0 max-w-md w-full h-full bg-gray-100 focus:outline-none duration-200 ${className}`,
        afterOpen: wasOpen ? 'sidebar-content--after-open' : '',
        beforeClose: 'sidebar-content--before-close',
      }}
      overlayClassName={{
        base: 'popup-overlay--base dark',
        afterOpen: wasOpen ? 'popup-overlay--after-open' : '',
        beforeClose: 'popup-overlay--before-close',
      }}
      closeTimeoutMS={200}
      onAfterOpen={afterOpen}
      onAfterClose={afterClose}
    >
      {children}
    </Modal>
  );
};
